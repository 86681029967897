import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../home";
import Layout from "../layout";
import React from "react";
import Case from "../case";

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="/:caseName" element={<Case />} />
                </Route>
                <Route path='*' element={<div>404</div>} />
            </Routes>
        </BrowserRouter>
    );
}
export default Router;