import { useStore } from "@/store"
import { observer } from "mobx-react-lite"
import DialogContent from '@mui/material/DialogContent';
import { Dialog} from "@mui/material";
import { ThemeContext } from "@/theme/themeContext";
import { useContext } from "react";

const ChangelogModal = () => {
    const { appStore } = useStore()
    const { theme } = useContext(ThemeContext)

    const handleChangelogClose = () => {
        appStore.changelogOpen = false
    }

    return (
        <Dialog open={appStore.changelogOpen} onClose={handleChangelogClose} >
            {/* <DialogTitle>Changelog</DialogTitle> */}

            <DialogContent className={` ${theme === 'dark' ? "bg-lightBlack" : "bg-white"} `}>

                <div>

                    <section class="text-gray-600 body-font">
                        <div class="container px-1 py-10 mx-auto flex flex-wrap">

                            <div class="flex relative pt-10 pb-20 sm:items-center md:w-2/3 mx-auto">
                                <div class="h-full w-6 absolute inset-0 flex items-center justify-center">
                                    <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                                </div>
                                <div class="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 text-white relative z-10 title-font font-medium text-sm">2</div>
                                <div class="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">

                                    <div class="flex-grow sm:pl-6 mt-6 sm:mt-0">
                                        <h2 class={` font-medium title-font ${theme==='dark' ? "text-white" :"text-gray-900"}   mb-1 text-xl `}>0.2.0</h2>
                                        <p class={` leading-relaxed text-sm ${theme==='dark' ? "text-gray-200" :"text-gray-900"} `}>
                                            增加light主题色<br/>
                                            优化markdown展示<br/>
                                            增加Changelog页面<br/>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="flex relative pb-20 sm:items-center md:w-2/3 mx-auto">
                                <div class="h-full w-6 absolute inset-0 flex items-center justify-center">
                                    <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                                </div>
                                <div class="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 text-white relative z-10 title-font font-medium text-sm">1</div>
                                <div class="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                                    <div class="flex-grow sm:pl-6 mt-6 sm:mt-0">
                                        <h2 class={` font-medium title-font  ${theme==='dark' ? "text-white" :"text-gray-900"} mb-1 text-xl `}>0.1.0</h2>
                                        <p class={` leading-relaxed text-sm ${theme==='dark' ? "text-gray-200" :"text-gray-900"} `}>
                                        价格文本内容重写<br/>
                                        首页文本内容更新，移动端适配<br/>
                                        携带上下文进行生成内容<br/>
                                        Lemo付款界面布局优化<br/>
                                        登录和注册的验证bug<br/>
                                        lemo支付生产环境下的流程测试<br/>
                                        移动端下的左侧菜单适配<br/>
                                        支付逻辑优化，点击升级后加载旋转按钮等待跳转到支付页面，防止多次点击升级按钮，形成多个订单<br/>
                                        清空功能<br/>
                                        title的图标替换<br/>
                                        对话顶部的token数计算规则问题<br/>
                                        首页优化内容和布局<br/>
                                        未登录用户点击”升级“按钮，应该提示登录<br/>
                                        Lemo支付成功是否回调刷新页面设置<br/>
                                        增加chat的copy功能，增加chat日期展示功能<br/>
                                        修复上下文切换混乱问题<br/>
                                         </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                </div>

            </DialogContent>
        </Dialog>
    )
}
export default observer(ChangelogModal)