import { PlaneIcon } from "../../constants";
import React, { useContext, useEffect, useRef, useState } from "react";
import useCompletion from "../../hooks/useCompletion";
import Welcome from "../welcome";
import { useStore } from "@/store";
import { observer } from "mobx-react-lite";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { green } from "@mui/material/colors";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { IconButton } from "@mui/material";
import ChatBody from "../chat-body";
import { ThemeContext } from "@/theme/themeContext";

const Case = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const chatBodyRef = useRef(null)
    const { showAlert } = useOutletContext()
    const { chatStore, sessionStore } = useStore()

    const { theme } = useContext(ThemeContext)

    const [cases, setCases] = useState({})
    const [promptWrapper, setPromptWrapper] = useState('')
    const [prompt, setPrompt] = useState('')

    //当前一条对话数据的四种状态: onWait onError onComplete onOverload
    const [chatEntity, setChatEntity] = useState(null)
    const [{ dataResult, isLoading, isError }, sendCompletion] = useCompletion({})

    useEffect(() => {

        /**
         * 更新组件状态和数据，从localstorage中获取数据并渲染页面
         * 由于setCases会触发页面重新渲染，而且这个渲染触发时机是无法控制的，所以这里的逻辑不能直接把cases的值用于其他业务逻辑，而只能用当前获取到的值"_case"
         */
        const updateCaseStatus = () => {
            let _case = location.state
            setCases(_case)
            chatStore.updateTitle(_case.name)
            chatStore.updateSystemContent(_case.systemContent)
            setPromptWrapper(_case.promptWrapper)
            var chatBotChatEntityList = localStorage.getItem(_case.localStorageKey)
            if (chatBotChatEntityList!=null) {
                chatStore.updateChatEntityList(JSON.parse(chatBotChatEntityList))
            } else {
                chatStore.updateChatEntityList([])
            }
        }

        if ((location.state == null) || (location.state === undefined)) {
            console.log('没有值传过来')
            navigate('/')
        } else {
            updateCaseStatus()
        }
    }, [location.state, navigate, chatStore])

    useEffect(() => {
        if (chatEntity!==null && chatEntity.chatRequestion!=='' && chatEntity.status==='onWait') {
            chatEntity.chatAnswer = (dataResult !== null && dataResult.code===200) ? dataResult.data.completion : ""

            if (!isLoading && isError) {
                if (dataResult && dataResult.code === 505) {
                    chatStore.updateCompletionStatus(chatEntity, 'onOverload')
                    // updateLocalStorage(chatEntity, 'onOverload')
                    localStorage.setItem(cases.localStorageKey, JSON.stringify(chatStore.chatEntityList))
                } else {
                    chatStore.updateCompletionStatus(chatEntity, 'onError')
                    // updateLocalStorage(chatEntity, 'onError')
                    localStorage.setItem(cases.localStorageKey, JSON.stringify(chatStore.chatEntityList))
                }
            } else if (!isLoading && !isError && dataResult && dataResult.data) { //正常的获取到结果
                chatEntity.totalToken = dataResult.data.usage.total_tokens
                chatStore.updateCompletionStatus(chatEntity, 'onComplete')
                chatStore.updateTokenNum(dataResult.data.usage.total_tokens)
                chatStore.updateConsumeQuantity(dataResult.data.usage.total_tokens)

                localStorage.setItem(cases.localStorageKey, JSON.stringify(chatStore.chatEntityList))
                // updateLocalStorage(chatEntity, 'onComplete')
            } else {
                console.log('不该出现的情况....', isLoading, isError, dataResult)
            }
        }

        const chatBodyList = chatBodyRef.current
        chatBodyList.scrollTop = chatBodyList.scrollHeight
        chatStore.openAIOnToggle = false
    }, [dataResult, chatEntity, chatStore, isError, isLoading, cases.localStorageKey])

    useEffect(() => {
        if (!isLoading && isError) {
            if (dataResult && dataResult.code === 505) {
                chatStore.updateCompletionStatus(chatEntity, 'onOverload')
                // updateLocalStorage(chatEntity, 'onOverload')
                localStorage.setItem(cases.localStorageKey, JSON.stringify(chatStore.chatEntityList))
            } else {
                chatStore.updateCompletionStatus(chatEntity, 'onError')
                // updateLocalStorage(chatEntity, 'onError')
                localStorage.setItem(cases.localStorageKey, JSON.stringify(chatStore.chatEntityList))
            }
        }
        const chatBodyList = chatBodyRef.current
        chatBodyList.scrollTop = chatBodyList.scrollHeight
        chatStore.openAIOnToggle = false
    }, [dataResult, chatEntity, chatStore, isError, isLoading, cases.localStorageKey])




    const validUserStatus = () => {
        if (!sessionStore.isLogin()) {
            sessionStore.loginOpen = true
            return false;
        }
        return true;
    }

    const _handleCompletion = () => {
        if (!validUserStatus()) {
            return;
        }
        var regu = "^[ ]+$";
        var reg = new RegExp(regu);
        if (prompt === null || prompt === '' || reg.test(prompt)) {
            showAlert({
                alertType: 'error',
                alertStatus: true,
                alertMsg: '输入内容错误'
            })
            return;
        }

        var _prompt = prompt
        if (promptWrapper !== null && promptWrapper !== '') {
            _prompt = promptWrapper.replaceAll("{}", prompt)
        }

        //构建当前会话类
        let currentChatEntity = {
            chatRequestion: prompt,
            chatAnswer: "",
            status: 'onWait',
            totalToken: 0
        }
        setChatEntity(currentChatEntity)
        chatStore.chatEntityList = [...chatStore.chatEntityList, currentChatEntity]

        sendCompletion({
            "prompt": prompt,
            "promptWrapper": _prompt,
            "messages": buildChatMessages(_prompt),
            "url": "/api/ilovegpt/chat",
            "chatId": cases.localStorageKey
        })

        chatStore.openAIOnToggle = true
        setPrompt('')
    }

    /**
     * build chat messages array
     * 
     * @param {*} currentRequestion 
     * @returns 
     */
    const buildChatMessages = (currentRequestion) => {
        var messages = []
        var systemContent = {
            "role": "system",
            "content": chatStore.systemContent
        }
        if (chatStore.systemContent !== '') {
            messages.push(systemContent)
        }

        var chatContextList = localStorage.getItem(cases.localStorageKey)
        if (chatContextList !== null) {
            var _chatContextList = JSON.parse(chatContextList)
            if (_chatContextList !== null && _chatContextList.length > 0) {
                _chatContextList.map((item, index) => {
                    if (item.status === 'onComplete') {
                        var userMessage = {
                            "role": "user",
                            "content": item.chatRequestion
                        }
                        var assistantMessage = {
                            "role": "assistant",
                            "content": item.chatAnswer
                        }
                        messages.push(userMessage, assistantMessage)
                    }
                    return {}
                })
            }
        }

        var currentUserMesage = {
            "role": "user",
            "content": currentRequestion
        }
        messages.push(currentUserMesage)
        return messages
    }


    /**
     * 清空当前对话
     */
    const handleCleanChat = () => {
        localStorage.removeItem(location.state.localStorageKey)
        chatStore.updateChatEntityList([])
    }

    //按发送按钮
    const handleCompletion = () => {
        _handleCompletion()
    }

    //按回车
    const onEnterKey = (e) => {
        if (e.keyCode === 13 && prompt !== '') {
            _handleCompletion()
        }
    }

    return (
        <>
            <div className={`flex flex-col items-center text-sm h-full  ${theme === 'dark' ? "bg-lightBlack" : "bg-white"} overflow-y-auto`} ref={chatBodyRef} >
                <div className={`flex w-full flex-wrap flex-col items-center text-gray-300 pt-[60px]`} >
                    <Welcome />

                    {/* 功能说明区域 */}
                    <div className="justify-center w-full md:max-w-2xl lg:max-w-3xl flex flex-col pt-10 px-6 pb-6 mb-6 rounded-md transition-colors duration-200 bg-gray-500/10">
                        <div className={`flex flex-row text-base gap-2 items-center ${theme === 'dark' ? "text-gray-300 " : "text-lightBlack"} `}>
                            <NotificationsIcon fontSize="medium" sx={{ color: green[500] }} />{cases.name}
                        </div>
                        <div className="flex flex-row pt-3">
                            <p className={` ${theme === 'dark' ? "text-gray-100/50" : "text-lightBlack"}    text-sm  font-light`}>
                                <div dangerouslySetInnerHTML={{ __html: cases.description }}></div>
                            </p>
                        </div>
                        <div className={`flex mt-3 pt-3 border-t  ${theme === 'dark' ? "text-gray-100/30 border-t-gray-100/10" : "text-gray-500 border-t-gray-300 "} text-xs font-light`}>
                            该聊天机器人由&nbsp;{cases.model}&nbsp;模型驱动
                        </div>
                    </div>

                    {/* 对话列表区域 */}
                    <div className="flex flex-col flex-1 w-11/12 text-base" >
                        {chatStore.chatEntityList.map((chatEntity, index) => <ChatBody chatEntity={chatEntity} index={index} />)}
                    </div>

                    {/* 底部高度占位 */}
                    <div className="w-full h-32 flex-shrink-0"></div>
                </div>

                {/* 底部输入框 - 底部的输入框不能抽象成单独组件，会有失去焦点的问题 */}
                <div className="absolute bottom-0 left-0 w-full border-t md:border-t-0 dark:border-white/20 md:border-transparent md:dark:border-transparent md:bg-vert-light-gradient bg-gray-800 !bg-transparent backdrop-blur-lg">
                    <div className="mx-2 flex flex-row gap-3 pt-2 last:mb-2 md:last:mb-6 lg:mx-auto lg:max-w-3xl lg:pt-0">

                        <div className="relative flex h-full flex-1 gap-x-2 items-center">

                            <div className="ml-1 mt-1.5 m-auto md:flex md:mb-2 gap-2 justify-center">
                                <IconButton size="small" edge="start" color="inherit" aria-label="open drawer" sx={{ mr: 1 }}>
                                    <div onClick={() => handleCleanChat()}>
                                        <CleaningServicesIcon fontSize="small" className={` ${theme === 'dark' ? "text-gray-100/50" : "text-gray-500"}`} />
                                    </div>
                                </IconButton>
                            </div>

                            <div className={`flex flex-col w-full py-2 pl-3 flex-grow md:py-3 md:pl-4 relative border
                                ${theme === 'dark' ? "bg-[rgba(64,65,79,var(--tw-bg-opacity))] border-black/10 " : "bg-white border-gray-300"} rounded-md`}>
                                <input
                                    value={prompt}
                                    onChange={(e) => { setPrompt(e.target.value) }}
                                    onKeyUp={onEnterKey}
                                    tabIndex="0"
                                    data-id="root"
                                    rows="1"
                                    className={`m-0 w-full  ${theme === 'dark' ? "text-gray-200" : "text-lightBlack "} text-base resize-none border-0 bg-transparent p-0 pr-7 focus:ring-0 focus-visible:ring-0 dark:bg-transparent outline-none overflow-y-hidden h-[23px]`}
                                ></input>
                                <button onClick={handleCompletion} className="absolute p-1 rounded-md text-gray-400 bottom-1.5 right-1 md:bottom-2.5 md:right-2 hover:bg-black">
                                    <PlaneIcon />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
export default observer(Case)