
const ChatIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30px" height="30px"
            viewBox="0 0 24 24">
            <circle cx="6" cy="12" r="5" fill="#D1D5DB">
                <animate attributeName="r" dur="1.5s" values="1;3;6;3;1;" repeatCount="indefinite" />
            </circle>
        </svg>
    )
}
export default ChatIcon;


