import { makeAutoObservable } from 'mobx'

class AppStore{

    //计划套餐弹出框
    planOpen = false

    wechatOpen = false
    
    mobileMenuShow = true

    changelogOpen = false

    constructor(){
        makeAutoObservable(this)
    }

    toggleMobileMenu(){
        this.mobileMenuShow = !this.mobileMenuShow
    }

    updateMobileMenu(newToggle){
        this.mobileMenuShow = newToggle
    }

}
export default AppStore