import SmartToyIcon from '@mui/icons-material/SmartToy';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import { ChatIcon } from '@/constants';
import { grey } from '@mui/material/colors';
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '@/theme/themeContext';
import MarkdownParse from '@/util/markdownParse';

const ChatBody = (props) => {
    const [copied, setCopied] = useState(false)
    const { theme } = useContext(ThemeContext)
    const handleCopy = () => {
        navigator.clipboard.writeText( props.chatEntity.chatAnswer)
        setCopied(true)
    }

    useEffect(() => {
        const timer = setTimeout(() => setCopied(false), 1500)
        return () => clearTimeout(timer)
    }, [copied])

    return (
        <>
            {/* 问题 */}
            <div key={props.index} className="w-full flex flex-row">
                <div className="w-0 md:w-1/12 lg:w-1/12"></div>
                <div className="w-full md:w-10/12 lg:w-10/12 px-3 pt-0 md:px-12 ">
                    <div className="flex pt-2 pb-4 items-start">
                        <div className="flex-shrink-0 mr-4">
                            <AccountCircleIcon sx={{ color: grey[700] }} />
                        </div>
                        <div className={`p-0 px-3 py-3 ${theme === 'dark' ? "text-gray-300 bg-blue-500/20" : "text-gray-100 bg-blue-600"} w-max rounded-md transition-colors duration-200`}>
                            {props.chatEntity.chatRequestion}
                        </div>
                    </div>
                </div>
                <div className="w-0 md:w-1/12 lg:w-1/12"></div>
            </div>

            {/* 回答 */}
            <div key={props.index + 1} className="w-full flex flex-row ">
                <div className="w-0 md:w-1/12 lg:w-1/12"></div>
                <div className="w-full md:w-10/12 lg:w-10/12 px-3 pt-0 md:px-12 ">
                    <div className="flex pt-2 pb-4 items-start group">

                        <div className="flex-shrink-0 mr-4">
                            {(props.chatEntity.status === 'onError' || props.chatEntity.status === 'onOverload') ? <ErrorOutlineOutlinedIcon color='error' /> : <SmartToyIcon color="success" />}
                        </div>
                        <div className={`p-0 px-3 py-3 ${theme === 'dark' ? "text-gray-300" : "text-lightBlack"} w-max rounded-md transition-colors duration-200 bg-gray-500/10`}>
                            {props.chatEntity.status === 'onWait' ? <ChatIcon /> : (props.chatEntity.status === 'onError' ? '出错了！请重试！' : (props.chatEntity.status === 'onOverload' ? 'Token已消耗完毕，请重新订阅！' :

                               <MarkdownParse content={props.chatEntity.chatAnswer} />

                            ))}
                        </div>

                        <div className="flex items-center pl-2 invisible group-hover:visible" onClick={handleCopy}>
                            {
                                (props.chatEntity.status === 'onComplete' && copied === false) ? <ContentCopyIcon fontSize="small" className="cursor-pointer hover:bg-gray-500/20" /> :
                                    ((props.chatEntity.status === 'onComplete' && copied === true) ? <DoneIcon fontSize='small' color='success' /> : <div></div>)
                            }
                        </div>
                    </div>
                </div>

                <div className="w-0 md:w-1/12 lg:w-1/12">
                </div>
            </div>
        </>
    )
}
export default ChatBody;