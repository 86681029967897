import { http } from "../util"
import { useEffect, useState } from "react"

const useCompletion = (initialParams) => {
    const [dataResult, setDataResult] = useState([])
    const [queryParams, setQueryParams] = useState(initialParams)
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    
    useEffect(()=>{
        const fetchData = async (queryParams)=>{
            setIsLoading(true)
            setIsError(false)
            try {
                await http.post(queryParams.url, queryParams).then(res => {
                    if(res!==undefined && res.code===200 && res.data!==null){
                        setDataResult(res)
                    }else {
                        setDataResult(res)
                        setIsError(true)  //除了200响应之外，所有的返回都认为是error状态
                    }
                    setIsLoading(false)
                })    
            } catch (error) {
                setIsError(true)
                setIsLoading(false)
            }
            setIsLoading(false)  
        }

        if(queryParams!==null && JSON.stringify(queryParams)!=='{}'){
            if(queryParams.prompt!==''){
                fetchData(queryParams)
            }else{
                console.log('用户输入内容为空')
                setIsLoading(false)
            }
        }
    }, [queryParams])
    return [{dataResult, isLoading, isError}, setQueryParams]
}
export default useCompletion