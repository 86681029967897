import { useStore } from "@/store"
import { observer } from "mobx-react-lite"
import DialogContent from '@mui/material/DialogContent';
import { Dialog} from "@mui/material";

const WechatModal = ()=>{
    const {appStore} = useStore()

    const handleWechatClose = ()=>{
        appStore.wechatOpen = false
    }

    return(
        <Dialog open={appStore.wechatOpen} onClose={handleWechatClose} >
            {/* <DialogTitle>wechat</DialogTitle> */}
            <DialogContent className="bg-lightBlack">
                <img alt="wechat" src="./wechat.jpg" />
            </DialogContent>
        </Dialog>
    )
}
export default observer(WechatModal)