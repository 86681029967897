import { useContext, useEffect, useState } from "react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LeftMenu from "../left-menu"
import { Outlet } from "react-router-dom"
import { Alert, IconButton, Snackbar } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import useDataApi from "@/hooks/useDataApi";
import { useStore } from "@/store";
import { observer } from "mobx-react-lite";
import LoginModal from "../login-modal";
import RegisteModal from "../registe-modal";
import PlanModal from "../plan-modal";
import ChatTitle from "../chat-title";
import WechatModal from "../wechat-modal";
import { ThemeContext } from "@/theme/themeContext";
import ChangelogModal from "../changelog-modal";

const Layout = () => {
    const {appStore, sessionStore} = useStore()
    const [{dataResult, isLoading, isError}, setQueryParams] = useDataApi({})

    const {theme} = useContext(ThemeContext)

    const [userInfo, setUserInfo] = useState(null)

    const [alertEntity, setAlertEntity] = useState({
        alertType: 'error',
        alertStatus: false,
        alertMsg: ''
    })

    //每次刷新页面同步localstorage数据到userinfo对象
    useEffect(()=>{
        var _userInfo =localStorage.getItem('userInfo')
        if(_userInfo!=null){
            setUserInfo(JSON.parse(_userInfo))
        }
    }, [])

    useEffect(() => {
        if(isError || (!Array.isArray(dataResult) && dataResult.code!==200)){
            setAlertEntity({
                alertType: 'error',
                alertStatus: true,
                alertMsg: '操作失败'
            })

        }else if(!isLoading && dataResult.code===200 && !Array.isArray(dataResult)){
            setUserInfo(dataResult.data)
            if(dataResult.data!=null){
                localStorage.setItem('userInfo', JSON.stringify(dataResult.data))
            }
            window.location.reload()

            setAlertEntity({
                alertType: 'success',
                alertStatus: true,
                alertMsg: '操作成功'
            })
        }
    }, [dataResult, isLoading, isError])

    const showAlert = (alert)=>{
        setAlertEntity(alert)
    }

    const handleAlertClose = ()=>{
        setAlertEntity({
            alertStatus: false
        })
    }

    const handleClickLoginOpen = ()=>{
        sessionStore.registeOpen = false
        sessionStore.loginOpen = true
    }

    const handleLogin = (userValue)=>{
        setQueryParams(userValue)
        sessionStore.loginOpen = false
    }
    
    const handleRegiste=(userValue)=>{
        setQueryParams(userValue)
        sessionStore.registeOpen = false
    }

    const handleShowMenu = ()=>{
        appStore.toggleMobileMenu()
    }

    return (
        <>
            <div>
                <LeftMenu />
                <div className={`flex h-screen flex-1 flex-col  ${appStore.mobileMenuShow ? "md:pl-[260px]" : ""} `}>
                    <main className="relative h-full w-full transition-width flex flex-col items-stretch flex-1">
                        <div className="flex-1 flex flex-col h-full w-full">

                            {/* 顶部固定导航栏 */}
                            <div className={`flex flex-row absolute left-0 border-t md:border-t-0 w-full border-dashed shadow-lg justify-between ${theme==='dark' ? "bg-lightBlack" : "bg-white"} 
                            flex-wrap p-4 items-center`}>

                                <IconButton size="small" edge="start" color="inherit" aria-label="open drawer" sx={{ mr: 1 }}>
                                    <div onClick={()=>handleShowMenu()}>
                                        <MenuIcon className={` ${theme==='dark' ? "text-gray-100/50  " : "text-lightBlack"} `} />
                                    </div>
                                </IconButton>

                                {
                                    <ChatTitle />
                                }
                                {
                                    userInfo!=null ? <p className={` ${theme==='dark'? "text-gray-100/50 " : "text-lightBlack font-medium" } text-sm `}>{userInfo.nickName}</p> : 
                                    <IconButton size="small" edge="start" color="inherit" aria-label="open drawer" sx={{ mr: 1 }} onClick={handleClickLoginOpen}>
                                        <AccountCircleIcon className={` ${theme==='dark' ? "text-gray-100/50" : "text-lightBlack"} `} /> 
                                    </IconButton>
                                }

                            </div>
                            <Outlet context={{showAlert}}/>
                        </div>

                        <LoginModal handleLogin={handleLogin} showAlert={showAlert} />
                        <RegisteModal handleRegiste={handleRegiste} showAlert={showAlert} />
                        <PlanModal showAlert={showAlert} />
                        <WechatModal />
                        <ChangelogModal />


                    </main>
                </div>

                {/* 通知 */}
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center'}} open={alertEntity.alertStatus} autoHideDuration={2000} onClose={handleAlertClose}>
                    <Alert severity={alertEntity.alertType} onClose={handleAlertClose} sx={{ width: '100%' }}>{alertEntity.alertMsg}</Alert>
                </Snackbar>
            </div>
        </>
    )
}
export default observer(Layout)