import { makeAutoObservable } from "mobx"

class SessionStore{
    loginOpen = false
    registeOpen = false

    constructor(){
        makeAutoObservable(this)
    }

    //判断是否已经登录了，判断原则是localstorage是否已经存储了userinfo和token
    isLogin(){
        return !(localStorage.getItem('userInfo')==null || localStorage.getItem('token')==null)
    }
}
export default SessionStore