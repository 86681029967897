import React from 'react'
import ChatStore  from './chat.store'
import SessionStore from './session.store'
import AppStore from './app.store'

class RootStore {
    constructor(){
        this.chatStore = new ChatStore()
        this.sessionStore = new SessionStore()
        this.appStore = new AppStore()
    }
}

const rootStore = new RootStore()
const context = React.createContext(rootStore)
const useStore = () => React.useContext(context)
export { useStore }