import { http } from "@/util"
import { useEffect, useState } from "react"

const useDataApi = (initialParams) => {
    const [pageNum, setPageNum] = useState(0)
    const [dataResult, setDataResult] = useState([])
    const [queryParams, setQueryParams] = useState(initialParams)
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    
    useEffect(()=>{
        const fetchData = async (queryParams)=>{
            setIsLoading(true)
            setIsError(false)
            try {
                
                // console.log("请求参数 => ", queryParams)
                
                await http.post(queryParams.url, queryParams).then(res => {
                    
                    // console.log('API返回结果 => ', res)

                    setDataResult(res)
                    setPageNum(res.pageNum)
                })    
            } catch (error) {
                setIsError(true)
            }
            setIsLoading(false)
        }

        if(queryParams!=null && JSON.stringify(queryParams)!=='{}'){
            fetchData(queryParams)
        }
    }, [queryParams])
    return [{dataResult, isLoading, isError, pageNum}, setQueryParams]
}
export default useDataApi